export default Object.freeze({
    ALERT_TYPE_ERROR: 'error',
    ALERT_TYPE_SUCCESS: 'success',
    ALERT_TYPE_INFO: 'info',
    MAX_NO_OF_QUESTIONS: 5,
    MODULE_DASHBOARD: 'dashboard',
    MODULE_SUPPORT: 'support',
    MODULE_USER_PROFILE: 'userProfile',
    MODULE_WIDGETS: 'widgets',
    MODULE_SETTINGS: 'settings',
    MODULE_COMPANIES: 'companies',
    MODULE_SITES: 'mySites',
    MODULE_RECORDS: 'records',
    MODULE_SERVICE_PROVIDERS: 'service-providers',
    SOCKET_ORGANIZATION_CHANNEL: 'private-organization-channel',
    MODULE_WORK_ORDERS: 'work-orders',
    SUB_MODULE_INVOICE: 'invoice',
    SOCKET_SEND_ALERT_MESSAGE: 'sent.alert.message',
    SOCKET_RECEIVE_ALERT_RESPONSE: 'sent.alert.response',
});
