import axios from 'src/app-axios';
import Constants from 'src/constants';

export const dashboard = {
    namespaced: true,
    state: {
        dataUsageStatistics: {},
        lastUpdatedAt: null,
        monthlyWorkCompletion: [],
        currentActiveCheckIns: [],
        currentSuspiciousActivities: [],
        highestJobHours: [],
        activeCheckInsCount: [],
    },
    getters: {
        getDataUsageStatistics (state) {
            return state.dataUsageStatistics;
        },
        getLastUpdatedAt (state) {
            return state.lastUpdatedAt;
        },
        getMonthlyWorkCompletion (state) {
            return state.monthlyWorkCompletion;
        },
        getCurrentActiveCheckIns (state) {
            return state.currentActiveCheckIns;
        },
        getCurrentSuspiciousActivities (state) {
            return state.currentSuspiciousActivities;
        },
        getHighestJobHours (state) {
            return state.highestJobHours;
        },
        getActiveCheckInsCount (state) {
            return state.activeCheckInsCount;
        },
    },
    mutations: {
        setDataUsageStatistics (state, data) {
            state.dataUsageStatistics = data;
        },
        setLastUpdatedAt (state, dateTime) {
            state.lastUpdatedAt = dateTime;
        },
        setMonthlyWorkCompletion (state, details) {
            state.monthlyWorkCompletion = details;
        },
        setCurrentActiveCheckIns (state, details) {
            state.currentActiveCheckIns = details;
        },
        setCurrentSuspiciousActivities (state, details) {
            state.currentSuspiciousActivities = details;
        },
        setHighestJobHours (state, details) {
            state.highestJobHours = details;
        },
        setActiveCheckInsCount (state, count) {
            state.activeCheckInsCount = count;
        },
    },
    actions: {

        async fetchMonthlyWorkCompletionData ({ dispatch, commit }, payload) {
            const url = 'api/jobs/completed';
            try {
              const response = await axios.get(url, payload);
              commit('setMonthlyWorkCompletion', response.data);
            } catch (exception) {
              dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your monthly work completion data.',
                type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
            }
        },
        async fetchCurrentActiveCheckInsData ({ dispatch, commit }) {
            const url = 'api/organizations/site-active-users/count';
            try {
              const response = await axios.get(url);
              commit('setCurrentActiveCheckIns', response.data);
            } catch (exception) {
              dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your current active checkins data.',
                type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
            }
        },
        async fetchCurrentSuspiciousActivities ({ dispatch, commit }, payload) {
            const url = 'api/suspicious-activity';
            try {
              const response = await axios.get(url, payload);
              commit('setCurrentSuspiciousActivities', response.data);
            } catch (exception) {
              dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your current suspicious activities count.',
                type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
            }
        },
        async fetchHighestJobHours ({ dispatch, commit }, payload) {
            const url = 'api/organizations/highest-job-details';
            try {
              const response = await axios.get(url, payload);
              commit('setHighestJobHours', response.data);
            } catch (exception) {
              dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your highest job hours chart.',
                type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
            }
        },
        async fetchActiveCheckInsCount ({ dispatch, commit }) {
            const url = 'api/organizations/active-users';

            try {
              const response = await axios.get(url);
              commit('setActiveCheckInsCount', response.data);
            } catch (exception) {
              dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your active check-ins count.',
                type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
            }
        },
        async fetchDataUsageStatistics ({ dispatch, commit }, { params }) {
            const url = 'api/organizations/data-usage';
            try {
                const response = await axios.get(url, { params: params });
                  commit('setDataUsageStatistics', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your data usage statistics.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        lastUpdatedAt ({ commit }, payload) {
            commit('setLastUpdatedAt', payload.updatedAt);
        },
    },
};
