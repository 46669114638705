import axios from 'src/app-axios';
import Constants from 'src/constants';

export const companies = {
    namespaced: true,
    state: {
        companiesList: [],
    },
    getters: {
        getCompaniesList (state) {
            return state.companiesList;
        },
    },
    mutations: {
        setCompaniesList (state, data) {
            state.companiesList = data;
        },
    },
    actions: {
        async fetchCompaniesList ({ dispatch, commit }) {
            const url = 'api/service-provider-company';
            try {
                const details = await axios.get(url);
                commit('setCompaniesList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting companies list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async createCompany ({ dispatch }, payload) {
            const url = 'api/service-provider-company';
            try {
              await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this Company.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
