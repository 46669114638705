import Vue from 'vue';
import Router from 'vue-router';
import { dsAppDomainUrl } from 'src/app-globals';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Auth
    {
      path: '/',
      name: 'Login',
      component: () => import('@/views/auth/Login'),
    },
    // Auth
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        requiresAuth: true,
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: 'pages/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: '/user',
          component: () => import('@/views/dashboard/ProfileSettings/ProfileSettings'),
        },
        // support
        {
          name: 'Support',
          path: '/support',
          component: () => import('@/views/dashboard/support/Support'),
        },
        // Account Settings
        {
          name: 'Settings',
          path: '/settings',
          component: () => import('@/views/dashboard/settings/Settings'),
        },
        // Settings
        {
          name: 'Feature Settings',
          path: '/feature-settings',
          component: () => import('@/views/dashboard/settings/SettingsLandingPage'),
        },
        // Service Providers
        {
          name: 'Service Providers',
          path: '/service-providers',
          component: () => import('@/views/dashboard/ServiceProviders/ServiceProviders'),
        },
        {
          name: 'Service Provider Details',
          path: '/service-providers/:externalId',
          component: () => import('@/views/dashboard/ServiceProviders/ServiceProviderDetailsLandingPage'),
        },
        {
          name: 'Service Providers Structure',
          path: '/service-providers/group',
          component: () => import('@/views/dashboard/ServiceProviders/serviceprovidergrouping/CreateServiceProvidersStructure'),
        },
        // Widgets
        {
          name: 'Widgets',
          path: '/widgets',
          component: () => import('@/views/dashboard/widgets/Widgets'),
        },
        {
          name: 'Widgets',
          path: '/widgets/alerts/:alertId/responses',
          component: () => import('@/views/dashboard/widgets/emergencymessages/EmergencyMessageResponses'),
        },
        // My Sites
        {
          name: 'Locations',
          path: 'locations',
          component: () => import('@/views/dashboard/locations/Locations'),
        },
        {
          name: 'Group Locations',
          path: '/my-sites/group',
          component: () => import('@/views/dashboard/locations/locationgrouping/CreateLocationStructure'),
        },
        {
          name: 'Add Locations',
          path: 'locations/create',
          component: () => import('@/views/dashboard/locations/AddLocations'),
        },
        {
          name: 'Add Locations',
          path: 'locations/:locationId/check-ins',
          component: () => import('@/views/dashboard/locations/SiteCheckIns'),
        },
        {
          name: 'Google Maps',
          path: 'locations/google-maps',
          component: () => import('@/views/dashboard/locations/GoogleMapsGeolocation'),
        },
        // Records
        {
          name: 'Check-In Records',
          path: 'records',
          component: () => import('@/views/dashboard/records/CheckInRecords'),
        },
        {
          name: 'Record Details',
          path: 'records/:recordId',
          component: () => import('@/views/dashboard/records/CheckInRecordDetails'),
        },
         // Work Orders
        {
          name: 'workOrders',
          path: '/work-orders',
          component: () => import('@/views/dashboard/workorders/WorkOrders'),
        },
        {
          name: 'workOrder CheckIns',
          path: '/work-orders/:workOrderId',
          component: () => import('@/views/dashboard/workorders/WorkOrderCheckIns'),
        },
        // Companies
        {
          name: 'Companies List',
          path: '/companies',
          component: () => import('@/views/dashboard/companies/CompaniesList'),
        },
        // Logout
        {
          path: '/logout',
          name: 'Logout',
          component: () => import('@/views/auth/Logout'),
        },
        // unknown routes
        {
          name: 'unknown',
          path: '*',
          component: () => import('@/views/dashboard/Unknown'),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('sc-user');

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    window.location.href = dsAppDomainUrl + '/logout-all';
    return;
  }

  next();
});

export default router;
