import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const serviceproviders = {
    namespaced: true,
    state: {
        createdserviceProvidersStructure: [],
        savedServiceProvidersStructure: {},
        serviceProviders: [],
        serviceProviderDocuments: [],
        allServiceProviderActivities: [],
        recentServiceProviderActivities: [],
        serviceProviderInvoices: [],
        serviceProviderDetails: {},
        leastActiveServiceProviders: [],
    },
    getters: {
        getCreatedServiceProvidersStructure (state) {
            return state.createdserviceProvidersStructure;
        },
        getSavedServiceProvidersStructure (state) {
            return state.savedServiceProvidersStructure;
        },
        getServiceProviders (state) {
            return state.serviceProviders;
        },
        getServiceProviderDocuments (state) {
            return state.serviceProviderDocuments;
        },
        getAllServiceProviderActivities (state) {
            return state.allServiceProviderActivities;
        },
        getRecentServiceProviderActivities (state) {
            return state.recentServiceProviderActivities;
        },
        getServiceProviderInvoices (state) {
            return state.serviceProviderInvoices;
        },
        getServiceProviderDetails (state) {
            return state.serviceProviderDetails;
        },
        getLeastActiveServiceProviders (state) {
            return state.leastActiveServiceProviders;
        },
    },
    mutations: {
        setCreatedServiceProvidersStructure (state, data) {
            state.createdserviceProvidersStructure = data;
        },
        setSavedServiceProvidersStructure (state, data) {
            state.savedServiceProvidersStructure = data;
        },
        setServiceProviders (state, details) {
            state.serviceProviders = details;
        },
        setServiceProviderDocuments (state, details) {
            state.serviceProviderDocuments = details;
        },
        setAllServiceProviderActivities (state, details) {
            state.allServiceProviderActivities = details;
        },
        setRecentServiceProviderActivities (state, details) {
            state.recentServiceProviderActivities = details;
        },
        setServiceProviderInvoices (state, details) {
            state.serviceProviderInvoices = details;
        },
        setServiceProviderDetails (state, details) {
            state.serviceProviderDetails = details;
        },
        setLeastActiveServiceProviders (state, details) {
            state.leastActiveServiceProviders = details;
        },
    },
    actions: {
        async createServiceProvider ({ dispatch }, payload) {
            const url = 'api/service-providers';
            try {
                await appAxios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating a new service provider.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        createServiceProvidersStructure (context, payload) {
            context.commit('setCreatedServiceProvidersStructure', payload);
        },
        async fetchSavedServiceProvidersStructure ({ dispatch, commit }) {
            const url = 'api/service-providers/group';
            try {
                const details = await appAxios.get(url);
                commit('setSavedServiceProvidersStructure', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your grouped service providers.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async groupServiceProviders  ({ dispatch, commit }, payload) {
            const url = 'api/service-providers/group';
            try {
                await appAxios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating the service providers group.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchServiceProviders ({ dispatch, commit }) {
            const url = 'api/service-providers';
            try {
                const details = await appAxios.get(url);
                commit('setServiceProviders', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting service providers.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchServiceProviderDocuments ({ dispatch, commit }, payload) {
            const url = `api/service-providers/${payload.serviceProviderId}/documents`;
            try {
                const details = await appAxios.get(url);
                commit('setServiceProviderDocuments', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting service provider documents.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchAllServiceProviderActivities ({ dispatch, commit }, payload) {
            const url = `api/service-providers/${payload.serviceProviderId}/activities`;
            try {
                const details = await appAxios.get(url);
                commit('setAllServiceProviderActivities', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting all service provider activities.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async dowloadDocment ({ dispatch }, payload) {
            const url = `api/documents/${payload.documentId}/download`;
            appAxios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = payload.fileName;
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
            }).catch(exception => {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in downloading Document file.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            });
        },
        async fetchRecentServiceProviderActivities ({ dispatch, commit }, { params, serviceProviderId }) {
            const url = `api/service-providers/${serviceProviderId}/activities`;
            try {
                const details = await appAxios.get(url, { params: params });
                commit('setRecentServiceProviderActivities', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting recent service provider activities.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchServiceProviderInvoices ({ dispatch, commit }, payload) {
            const url = `api/service-providers/${payload.serviceProviderId}/invoices`;
            try {
                const details = await appAxios.get(url);
                commit('setServiceProviderInvoices', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting service provider invoices.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchServiceProviderDetails ({ dispatch, commit }, payload) {
            const url = `api/service-providers/${payload.serviceProviderId}`;
            try {
                const details = await appAxios.get(url);
                commit('setServiceProviderDetails', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting Service Provider details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async updateServiceProviderDetails ({ dispatch }, payload) {
            const url = `api/service-providers/${payload.serviceProviderId}`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating the service provider details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchLeastActiveServiceProviders ({ dispatch, commit }) {
            const url = 'api/organizations/least-active-users';
            try {
                const details = await appAxios.get(url);
                commit('setLeastActiveServiceProviders', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting least active service providers list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
};
