// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import './plugins/base';
import './plugins/vee-validate';
import vuetify from './plugins/vuetify';
import './plugins/vuedatetimepicker';
import i18n from './i18n';
import axios from './app-axios';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created () {
    const userInfo = localStorage.getItem('sc-user');
    if (userInfo) {
      const userData = JSON.parse(userInfo);
      this.$store.commit('setUserData', userData);
    }

    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout');
        }

        return Promise.reject(error);
      },
    );
  },
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
