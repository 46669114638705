import axios from 'src/app-axios';
import Constants from 'src/constants';

export const workorders = {
    namespaced: true,
    state: {
        workOrders: [],
        workOrderCheckIns: {},
    },
    getters: {
        getWorkOrders (state) {
            return state.workOrders;
        },
        getWorkOrderCheckIns (state) {
            return state.workOrderCheckIns;
        },
    },
    mutations: {
        setWorkOrders (state, details) {
            state.workOrders = details;
        },
        setWorkOrderCheckIns (state, details) {
            state.workOrderCheckIns = details;
        },
    },
    actions: {
        async createWorkOrder ({ dispatch }, payload) {
            const url = 'api/job-details';
            try {
                await axios.post(url, payload.workDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating work orders.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchWorkOrders ({ dispatch, commit }) {
            const url = 'api/job-details';
            try {
                const details = await axios.get(url);
                commit('setWorkOrders', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the Work orders list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async  updateWorkOrderStatus ({ dispatch }, payload) {
            const url = `api/job-details/${payload.workOrderId}`;
            try {
                await axios.put(url, payload.workOrderStatus);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating your work order status.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchWorkOrderCheckIns ({ dispatch, commit }, payload) {
            const url = `api/jobs/${payload.workOrderId}/checkin-records`;
            try {
                const details = await axios.get(url, payload);
                commit('setWorkOrderCheckIns', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the Work order check-ins list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
};
