import axios from 'src/app-axios';
import Constants from 'src/constants';

export const records = {
    namespaced: true,
    state: {
        checkInRecords: [],
        checkInRecordDetails: {},
    },
    getters: {
        getCheckInRecords (state) {
            return state.checkInRecords;
        },
        getRecordDetails (state) {
            return state.checkInRecordDetails;
        },
    },
    actions: {
        async fetchCheckInRecords ({ dispatch, commit }) {
            const url = 'api/records';
            try {
                const records = await axios.get(url);
                commit('setCheckInRecords', records.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting check-in records list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchRecordDetails ({ dispatch, commit }, payload) {
            const url = `api/records/${payload.record_id}`;
            try {
                const records = await axios.get(url);
                commit('setRecordDetails', records.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting check-in records list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchFilteredRecordsList ({ dispatch, commit }, payload) {
            const url = 'api/records/filter';
            try {
                const records = await axios.get(url, payload);
                commit('setCheckInRecords', records.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the filtered check-in records list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
    },
    mutations: {
        setCheckInRecords (state, records) {
            state.checkInRecords = records;
        },
        setRecordDetails (state, details) {
            state.checkInRecordDetails = details;
        },
    },
};
