import axios from 'src/app-axios';
import Constants from 'src/constants';

export const locations = {
    namespaced: true,
    state: {
      markedLocationDetails: {},
      locationsList: [],
      savedLocationDetails: {},
      createdLocationStructure: [],
      savedLocationStructure: {},
      siteCheckIns: [],
    },

    getters: {
      getMarkedLocationDetails (state) {
        return state.markedLocationDetails;
      },
      getLocationsList (state) {
        return state.locationsList;
      },
      getSavedLocationDetails (state) {
        return state.savedLocationDetails;
      },
      getCreatedLocationStructure (state) {
        return state.createdLocationStructure;
      },
      getSavedLocationStructure (state) {
       return state.savedLocationStructure;
      },
      getSiteCheckIns (state) {
        return state.siteCheckIns;
      },
    },
    mutations: {
      setMarkedLocationDetails (state, data) {
        state.markedLocationDetails = data;
      },
      clearMarkedLocationDetails (state) {
        state.markedLocationDetails = {};
      },
      setLocationsList (state, data) {
        state.locationsList = data;
      },
      setSavedLocationDetails (state, details) {
        state.savedLocationDetails = details;
      },
      clearSavedLocationDetails (state) {
        state.savedLocationDetails = {};
      },
      setCreatedLocationStructure (state, data) {
        state.createdLocationStructure = data;
      },
      setSavedLocationStructure (state, data) {
        state.savedLocationStructure = data;
      },
      setSiteCheckIns (state, data) {
        state.siteCheckIns = data;
      },
    },
    actions: {
      storeMarkedLocationDetails ({ commit }, details) {
        commit('setMarkedLocationDetails', details);
      },
      removeMarkedLocationDetails ({ commit }) {
        commit('clearMarkedLocationDetails');
      },
      async fetchLocationsList ({ dispatch, commit }) {
        const url = 'api/my-sites';
        try {
          const response = await axios.get(url);
          commit('setLocationsList', response.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting your sites list.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      saveLocationDetails ({ commit }, details) {
        commit('setSavedLocationDetails', details);
      },
      removeSavedLocationDetails ({ commit }) {
        commit('clearSavedLocationDetails');
      },
      async addLocation ({ dispatch }, payload) {
        const url = 'api/my-sites';
        try {
          await axios.post(url, payload.locationDetails);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in adding your location to the sites list.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
        }
      },
      createLocationStructure (context, payload) {
        context.commit('setCreatedLocationStructure', payload);
      },
      async fetchSavedLocationStructure ({ dispatch, commit }) {
        const url = 'api/my-sites/group';
        try {
            const details = await axios.get(url);
            commit('setSavedLocationStructure', details.data);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in getting your grouped locations.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
        }
      },
      async groupLocations  ({ dispatch, commit }, payload) {
        const url = 'api/my-sites/group';
        try {
            await axios.post(url, payload);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in creating the location group.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
      },
      async fetchSiteCheckInsList ({ dispatch, commit }, payload) {
        const url = `api/my-sites/${payload.location_id}/checkins`;
        try {
          const response = await axios.get(url);
          commit('setSiteCheckIns', response.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting selected sites check-in list.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
    },
};
